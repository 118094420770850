.Credit .creditsText {
  color: #608686;
  display: block;
  margin-left: 6px;
}

.Credit .creditsLink {
  text-decoration: underline;
  color: #608686;
}
