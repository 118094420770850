.TipCard .card-body {
  min-width: 12rem;
  min-height: 7rem;
  border: 1px solid #608686;
  border-radius: 4px;
  color: #608686;
}

.TipCard .teaFlavour {
  font-weight: bold;
}
