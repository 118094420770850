.App {
  margin: 20px;
}

.App .teaAppTitle {
  color: #56d5c2;
  margin-top: 15px;
}

.App .teaApp,
.snackApp {
  margin: 5px;
  max-width: 35rem;
  min-width: 11rem;
}

.App .snackAppTitle {
  color: #ffc107;
  margin-top: 15px;
}

.App .snackButton,
.teaButton {
  display: block;
  margin: 25px 0 0px 60px;
}

.App .closeButton {
  margin: 25px 0 0px 60px;
}

.App .bothLink {
  display: block;
  margin: 0 0 0 60px;
  font-size: 12px;
}

@media (max-width: 576px) {
  .App .snackButton,
  .teaButton {
    display: block;
    margin: 0px;
  }
}
