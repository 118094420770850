.Weather .mainCardWrapper {
  max-width: 35rem;
}

.Weather .mainCard {
  border: 2pt solid #56d5c2;
  border-radius: 4px;
  background-color: #dbf1f1;
  font-family: ubuntu;
}

.Weather .location {
  text-transform: uppercase;
}

.Weather h2 {
  font-size: 24px;
  color: #3a403d;
}

.Weather .mainInfo {
  min-width: 11rem;
}

.Weather .interactive {
  min-width: 11rem;
}

.Weather .btn-group {
  border-radius: 4px;
  height: 25px;
  padding: 0 auto;
}

.Weather .activeUnitWrapper {
  background-color: #608686;
  font-size: 12px;
  padding: 0 auto;
  height: 25px;
}

.Weather .alternativeUnitWrapper {
  background-color: #6ca4a4;
  font-size: 12px;
  padding: 0 auto;
  height: 25px;
}

.Weather .secondaryInfo {
  color: #608686;
  font-size: 12px;
}

.Weather .forecastHeader {
  font-weight: bold;
  font-size: 16px;
  color: #3a403d;
}

.Weather .citySearch {
  border: 1px solid #608686;
}

.Weather .button-addon2 {
  background-color: #ffb6b6;
}

.Weather .loadingCard {
  border: 2pt solid #56d5c2;
  border-radius: 4px;
  background-color: #dbf1f1;
  height: 475px;
  width: 561px;
}
