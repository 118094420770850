.WeatherSnack .mainCardWrapper {
  max-width: 35rem;
}

.WeatherSnack .mainCard {
  border: 2pt solid #ffc107;
  border-radius: 4px;
  background-color: #f7fbe1;
  font-family: ubuntu;
}

.WeatherSnack .location {
  text-transform: uppercase;
}

.WeatherSnack h2 {
  font-size: 24px;
  color: #3a403d;
}

.WeatherSnack .mainInfo {
  min-width: 11rem;
}

.WeatherSnack .interactive {
  min-width: 11rem;
}

.WeatherSnack .btn-group {
  border-radius: 4px;
  height: 25px;
  padding: 0 auto;
}

.WeatherSnack .activeUnitWrapper {
  background-color: #608686;
  font-size: 12px;
  padding: 0 auto;
  height: 25px;
}

.WeatherSnack .alternativeUnitWrapper {
  background-color: #6ca4a4;
  font-size: 12px;
  padding: 0 auto;
  height: 25px;
}

.WeatherSnack .secondaryInfo {
  color: #608686;
  font-size: 12px;
}

.WeatherSnack .forecastHeader {
  font-weight: bold;
  font-size: 16px;
  color: #3a403d;
}
.WeatherSnack .citySearch,
.button-addon2 {
  border: 1px solid #608686;
}

.WeatherSnack .loadingCard {
  border: 2pt solid #ffc107;
  border-radius: 4px;
  background-color: #f7fbe1;
  height: 475px;
  width: 561px;
}
